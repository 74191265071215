import React, { useEffect, useState } from "react"
import firebase, { firebaseError } from "../src/lib/firebase"
import { useFirebaseUser } from "../src/lib/firebase"
import { Formik } from "formik"
import * as Yup from "yup"
import TextField from "../src/components/TextField"
import { fetchJson } from "../src/lib/fetcher"

export const loginOrSignUp = async (idToken, isNewUser, bodyParams) => {
  const path = isNewUser ? "/signup" : "/login"
  const csrfToken = document.head.querySelector("meta[name='csrf-token']").getAttribute("content")
  const headers = {
    "Content-Type": "application/json",
    "X-CSRF-TOKEN": csrfToken,
    Authorization: `Bearer ${idToken}`,
  }
  try {
    await fetchJson(path, {
      method: "POST",
      credentials: "include",
      mode: "cors",
      headers,
      body: bodyParams,
    })

    const searchParams = new URLSearchParams(window.location.search)
    const nextUrl = searchParams && searchParams.get("next")

    if (isNewUser) {
      // トースト表示 TODO
      // dispatch({ type: "ADD_TOAST", payload: { id: Math.random(), type: 'success', text: '登録が完了しました' } })

      // プロフィール設定画面にリダイレクト (new=1 をつける)
      window.location.href = nextUrl
        ? `/setting/profile?new=1&next=${encodeURIComponent(nextUrl)}`
        : "/setting/profile?new=1"
    } else {
      // トースト表示 TODO
      // dispatch({ type: "ADD_TOAST", payload: { id: Math.random(), type: 'success', text: 'ログインしました' } })

      // リダイレクト
      window.location.href = nextUrl || "/"
    }
  } catch (e) {
    console.error(e.message)
    console.error(e.data.message)
  }
}

export const useLogin = () => {
  const [loginLoading, setLoginLoading] = useState(false)

  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        if (result.user) {
          setLoginLoading(true)

          const bodyParams = result.credential
            ? JSON.stringify({
                credential: result.credential,
                providerData: result.user.providerData,
              })
            : null

          const isNewUser = result.additionalUserInfo.isNewUser

          result.user
            .getIdToken(true)
            .then((idToken) => {
              loginOrSignUp(idToken, isNewUser, bodyParams)
            })
            .catch((error) => {
              setLoginLoading(false)
              console.error(`Firebase getIdToken failed!: ${error.message}`)
            })
        }
      })
      .catch((error) => {
        setLoginLoading(false)
        console.error(error)
        window.alert(`ログインに失敗しました\n${firebaseError(error)}`)
      })
  }, [])

  return { loginLoading }
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email("メールアドレスの形式で入力してください").required("入力してください"),
  password: Yup.string().required("入力してください"),
})

export default function LoginForAdmin() {
  const { loginLoading } = useLogin()
  const [user, loadingUser, userError] = useFirebaseUser()

  async function handleOnSubmit(values, actions) {
    const { email, password } = values

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        if (result.user) {
          const bodyParams = result.credential
            ? JSON.stringify({
                credential: result.credential,
                providerData: result.user.providerData,
              })
            : null

          const isNewUser = result.additionalUserInfo.isNewUser

          result.user
            .getIdToken(true)
            .then((idToken) => {
              loginOrSignUp(idToken, isNewUser, bodyParams)
            })
            .catch((error) => {
              console.error(`Firebase getIdToken failed!: ${error.message}`)
            })
        }

        actions.setSubmitting(false)
      })
      .catch((error) => {
        console.log(error)
        actions.setFieldError("general", firebaseError(error))
        actions.setSubmitting(false)
      })
  }

  // ローディング中
  if (loadingUser || loginLoading) {
    return null
  }

  return (
    <div className="mb-8">
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <TextField type="email" name="email" placeholder="メールアドレス" />
            </div>

            <div className="mb-2">
              <TextField type="password" name="password" placeholder="パスワード" />
            </div>

            <div className="text-center">
              <button
                type="submit"
                className={`button is-submit bg-blue-500 text-white ${
                  isSubmitting ? "is-loading opacity-50" : ""
                }`}
                disabled={isSubmitting}
              >
                ログイン{isSubmitting ? "?" : ""}
              </button>
              {errors.general && (
                <div className="mt-4 text-center text-red-500">{errors.general}</div>
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}
